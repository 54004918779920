<template>
  <div class="container-fluid list-container">

    <!-- Page Header Section -->
    <div class="container-fluid d-flex justify-content-between">
      <div class="title">
        Team
      </div>
      <div class="d-flex align-items-center mb-3">
        <div v-if="teamsForUser.length > 1">
          <TeamSelection :user-teams="currentUser.permissions.teams_for_user"/>
        </div>
        <div v-if="currentUserIsAdmin"
             class="d-flex align-items-center"
        >
          <div v-if="!isPublicTenantSelected">
            <TeamCreation/>
          </div>
          <UserCreation/>
        </div>
      </div>

    </div>
    <!-- Success/error Message for team/user creation -->
    <div v-if="successMessage || errorMessage"
         class="m-1 p-1 w-100"
    >
      <b-alert :variant="errorMessage ? 'warning' : 'success' "
               dismissible
               show
      >
        <div v-html="successMessage"></div>
        <div v-html="errorMessage"></div>
      </b-alert>
    </div>

    <div v-if="teamsForUser.length > 0">
      <TeamTreeDisplay :team-tree="allTeams"
                       :root-tree="rootTree"
      ></TeamTreeDisplay>
    </div>
    <div v-else>
      There are yet no teams created for you. Please contact your administrator.
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import TeamCreation from "@/apps/user/TeamManagementComponents/TeamCreation"
import UserCreation from "@/apps/user/TeamManagementComponents/UserCreation"
import TeamTreeDisplay from "@/apps/user/TeamManagementComponents/TeamTreeDisplay"
import TeamSelection from "@/apps/user/TeamManagementComponents/TeamSelection"

export default {
  name: "TeamList",
  components: {
    UserCreation,
    TeamCreation,
    TeamTreeDisplay,
    TeamSelection
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/user",
      allTeams: "teams/getTeamTreeForUser",
      errorMessage: "teams/getErrorMessage",
      successMessage: "teams/getSuccessMessage"
    }),
    teamsForUser () {
      return this.currentUser.permissions.teams_for_user
    },
    currentUserIsAdmin () {
      return this.currentUser.is_admin
    },
    rootTree () {
      return this.allTeams.length > 0 ? this.allTeams : []
    },
    isPublicTenantSelected () {
      const tenantsForUser = this.currentUser.permissions.tenants
      if (tenantsForUser !== undefined) {
        const selectedTenant = tenantsForUser.filter(tenant => tenant.selected === true)
        return selectedTenant.length > 0 ? selectedTenant[0].schema_name === "public" : false
      }
      return false
    }
  },
  mounted () {
    this.getTeamTreeForCurrentUser() // this initializes teams store
    this.resetMessages()
  },
  methods: {
    ...mapActions({
      getTeamTreeForCurrentUser: "teams/getTeamTreeForUser"
    }),
    ...mapMutations({
      setErrorMessage: "teams/setErrorMessage",
      setSuccessMessage: "teams/setSuccessMessage"
    }),
    resetMessages () {
      this.setErrorMessage(null)
      this.setSuccessMessage(null)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
