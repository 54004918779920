<template>
  <div v-if="!!value"
       :class="datetimeDisplayClass"
  >
    <div v-if="displayDate"
         :class="dateDisplayClass"
    >
      {{ date }}
    </div>
    <div v-if="displayTime"
         :class="timeDisplayClass">
      {{ time }}
    </div>
  </div>
</template>

<script>

import moment from "moment"

export default {
  name: "BaoDateTimeDisplay",

  props: {
    value: {
      required: true
    },
    displayDate: {
      type: Boolean,
      default: true
    },
    displayTime: {
      type: Boolean,
      default: true
    },
    datetimeDisplayClass: {
      type: String,
      default: ""
    },
    dateDisplayClass: {
      type: String,
      default: ""
    },
    timeDisplayClass: {
      type: String,
      default: "text-muted"
    },
    datetimeFormat: {
      type: String,
      default: "YYYY-MM-DDThh:mm:ss"
    }
  },

  computed: {
    date () {
      return this.getDateTime(this.value).dateString
    },
    time () {
      return this.getDateTime(this.value).timeString
    }
  },

  methods: {
    getDateTime (datetime) {
      const formattedDate = this.getFormattedDate(datetime)
      return {
        dateString: new Date(formattedDate).toLocaleDateString("de-DE"),
        timeString: new Date(formattedDate).toLocaleTimeString("de-DE")
      }
    },
    getFormattedDate (datetime) {
      const inputFormats = [
        this.datetimeFormat,
        "YYYY-MM-DD",
        "DD.MM.YYYY",
        "hh:mm:ss",
        "hh:mm DD.MM.YYYY"
      ]
      return moment(datetime, inputFormats).format()
    }

  }
}
</script>

<style scoped lang="scss">

</style>
