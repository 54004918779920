<template>
  <div>
    <b-button id="invite-button-202012301559"
              class="my-auto ml-4 px-4"
              @click="openTeamCreateModal= !openTeamCreateModal"
    >
      <img src="../../../../public/img/icons/new-team.svg" />
      {{staticText.createNewTeamLabel}}
    </b-button>

    <!-- Modals   -->
    <b-modal id="team-create-modal-20234121149"
             v-model="openTeamCreateModal"
             :ok-title="staticText.createTeamText"
             :cancel-title="staticText.cancelTitle"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true"
             :busy="busy"
             :ok-disabled="!selectedParentTeam || !newTeamName"
             :hide-header-close="busy"
             @ok="createTeam"
             @hide="resetInputAndErrors"
    >
      <template v-slot:modal-title>
        <img src="../../../../public/img/icons/new-team.svg" class="mr-2"/>
        <span>{{ staticText.createNewTeamLabel }}</span>
      </template>

      <template v-slot:modal-header-close>
        <img src="../../../../public/img/icons/close-icon.svg"/>
      </template>

      <div v-if="busy">
        <progress-indicator ></progress-indicator>
        {{staticText.loadingLabel}}
      </div>
      <b-form-group v-else>
        <b-form-group class="mb-3">
          <label for="team-name">{{ staticText.teamNameLabel }}</label>
          <b-form-input id="team-name"
                        v-model="newTeamName"
                        :state="validateNameInput()"
                        type="text"
                        :placeholder="staticText.teamNamePlaceholder"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>

        <label for="parent-team">{{ staticText.parentTeamLabel }}</label>
        <treeselect id="parent-team"
                    class="mb-3"
                    v-model="selectedParentTeam"
                    :options="allTeams"
                    :normalizer="normalizer"
                    :placeholder="staticText.parentTeamPlaceholder"
                    search-nested
        ></treeselect>

        <label for="team-members-from">{{ staticText.addExistingUsersLabel }} </label>
        <treeselect id="team-members-from"
                    :options="allTeams"
                    :normalizer="normalizer"
                    :placeholder="staticText.selectExistingTeamsLabel"
                    :multiple="true"
                    :flat="true"
                    v-model="selectedTeams"
                    class="mb-3"
                    search-nested
                    @input="updateTeamMembers"
        ></treeselect>
        <div v-if="selectedTeams.length > 0">
          <treeselect id="team-members"
                      v-model="selectedMembers"
                      :options="teamsWithMembers"
                      :normalizer="teamWithMembersNormalizer"
                      :loading="true"
                      :placeholder="staticText.selectUsersPlaceholder"
                      :noChildrenText="staticText.noUsersAvailableText"
                      :disable-branch-nodes="true"
                      :multiple="true"
                      search-nested
          ></treeselect>

          <div class="mt-3" v-if="selectedMembers.length > 0">
            <bao-toggle-button
              :label="staticText.copyUsersSettingLabel"
              :type="'checkbox'"
              :is-active="copyUserSettings"
              @toggle="toggleCopyUserSettings"
            ></bao-toggle-button>
          </div>

        </div>
      </b-form-group>
      <div v-if="!!teamCreationError">
        <b-alert variant="danger"
                 dismissible
                 :show="!!teamCreationError"
                 @dismissed="teamCreationError=null"
        >{{teamCreationError}}
        </b-alert>
      </div>

    </b-modal>

  </div>

</template>

<script>
import axios from "axios"
import Treeselect from "@riophae/vue-treeselect"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { treeSelectUtils } from "@/apps/user/TeamManagementComponents/TeamManagementUtils"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import BaoToggleButton from "@/apps/base/BaoToggleButton"

export default {
  name: "TeamCreation",
  components: {
    Treeselect,
    ProgressIndicator,
    BaoToggleButton
  },
  data () {
    return {
      error: null,
      teamCreationError: null,
      busy: false,
      axios,
      newTeamName: null,
      teamsWithMembers: [],
      openTeamCreateModal: false,
      selectedParentTeam: null,
      selectedTeams: [],
      selectedMembers: [],
      copyUserSettings: false,
      staticTextDefault: {
        cancelTitle: "Cancel",
        teamNameLabel: "Team Name",
        teamNamePlaceholder: "Enter team name",
        parentTeamLabel: "Parent Team",
        parentTeamPlaceholder: "Select a parent team",
        addExistingUsersLabel: "Add existing users to the new team (optional)",
        selectExistingTeamsLabel: "Pick existing teams with users",
        selectUsersPlaceholder: "Pick users from selected teams",
        createNewTeamLabel: "Create New Team",
        noUsersAvailableText: "No users are available on this team",
        createTeamText: "Create Team",
        emptyTeamNameInvalidMessage: "Team Name can not be empty.",
        createTeamSuccessMessage: "A new team has been successfully created.",
        loadingLabel: "Team creation is in progress. This can take up to 2 minutes.",
        nameDuplicationErrorMessage: "The team with this name already exists. Please try a different name.",
        teamCreationErrorMessage: "Something went wrong during team creation process. Make sure you provided " +
          "valid inputs. If problem persists, please contact your Administrator.",
        copyUsersSettingLabel: "Copy user's settings to new team"
      }
    }
  },
  watch: {
    allTeams (val, oldVal) {
      if (val && val !== oldVal) {
        // set parent team to preselect
        this.selectedParentTeam = val[0].id
      }
    }
  },
  mounted () {
    this.getTeamTreeForCurrentUser()
  },
  computed: {
    ...mapGetters({
      allTeams: "teams/getTeamTreeForUser"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    ...treeSelectUtils.methods,
    ...mapActions({
      getTeamTreeForCurrentUser: "teams/getTeamTreeForUser",
      createNewTeam: "teams/createNewTeam",
      getMembersForTeams: "teams/getMembersForTeams"
    }),
    ...mapMutations({
      setSuccessMessage: "teams/setSuccessMessage",
      setErrorMessage: "teams/setErrorMessage"
    }),
    resetInputAndErrors () {
      this.teamCreationError = null
      this.newTeamName = null
      this.selectedTeams = []
      this.selectedMembers = []
    },
    validateNameInput () {
      if (this.newTeamName === null) return null
      if (this.newTeamName === "") {
        this.error = this.staticText.emptyTeamNameInvalidMessage
      } else this.error = null // reset error
      return this.newTeamName !== ""
    },
    toggleCopyUserSettings () {
      this.copyUserSettings = !this.copyUserSettings
    },
    async updateTeamMembers () {
      // teams is an array with ids.
      await this.getMembersForTeams(this.selectedTeams).then(result => {
        this.teamsWithMembers = result
      })
    },
    async createTeam (event) {
      event.preventDefault() // prevents modal close when team creation is in progress.
      this.busy = true
      const teamData = {
        name: this.newTeamName,
        parent: this.selectedParentTeam,
        users: this.selectedMembers,
        copy_user_settings: this.copyUserSettings
      }
      await this.createNewTeam(teamData).then(result => {
        this.busy = false
        this.$bvModal.hide("team-create-modal-20234121149")
        this.setSuccessMessage(this.staticText.createTeamSuccessMessage)
      }).catch(error => {
        // this means team creation failed for some reason.
        this.busy = false
        if (error.response.data.name_duplication_error) {
          this.teamCreationError = this.staticText.nameDuplicationErrorMessage
        } else {
          this.teamCreationError = this.staticText.teamCreationErrorMessage
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
