<template>
  <div id="bao-toggle-div-21931058"
       :class="['toggleBtn', 'cursor-pointer', buttonClass, {'is-disabled': isDisabled}]"
       @click.stop="$emit('toggle')"
  >
    <span
      v-if="!!iconClass"
      id="icon"
      class="d-flex align-items-center"
    >
      <img
        :src="iconClass"
        :class="['toggle-icon', {'inactive': !isActive && type === 'dropdown'}]"
      />
    </span>
    <span v-if="!!label" class="mx-2">
      {{label}}
    </span>
  </div>
</template>

<script>

export default {
  name: "BaoToggleButton",
  data () {
    return {
      options: {
        checkbox: {
          activeIcon: "/img/icons/checked-checkbox.svg",
          inactiveIcon: "/img/icons/unchecked-checkbox.svg"
        },
        radio: {
          activeIcon: "/img/icons/checked-radio.svg",
          inactiveIcon: "/img/icons/unchecked-radio.svg"
        },
        agenda: {
          activeIcon: "/img/icons/list_slash.svg",
          inactiveIcon: "/img/icons/list-ul-solid.svg"
        },
        dropdown: {
          activeIcon: "/img/icons/chevron-up.svg"
        }
      }
    }
  },
  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "checkbox"
    },
    buttonClass: {
      type: [String, Array],
      default: ""
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    iconClass () {
      return [
        this.isActive || this.type === "dropdown" ? this.getActiveIconClass(this.type) : this.getInActiveIconClass(this.type)
      ]
    }
  },
  methods: {
    getActiveIconClass (type) {
      return this.options[type.toLowerCase()].activeIcon
    },
    getInActiveIconClass (type) {
      return this.options[type.toLowerCase()].inactiveIcon
    }
  }
}

</script>

<style scoped lang="scss">

.toggleBtn {
  display: inline-flex;
  align-items: center;
  text-align: left;

  &.is-disabled {
    pointer-events: none;
    span#icon {
      background-color: $grey;
    }
  }
}

img{
  height: 18px;
  width: 18px;
}

.toggle-icon {
  transition: 0.3s ease-in;
  &.inactive {
    transform: rotate(180deg);
  }
}

</style>
