<template>
  <div>
    <b-dropdown id="team-selection-2023717315" no-caret right
    >
      <b-dropdown-item v-for="option in userTeams"
                       :key="option.schema_name"
                       :active="option.selected"
                       @click="switchTeam(option)"
      >
        <span :class="{'font-weight-bold': !option.parent}">
          {{option.name}}
        </span>
      </b-dropdown-item>

      <template #button-content>
        <img src="../../../../public/img/icons/change-team.svg" />
        {{ staticText.changeTeamTitle }}
      </template>

    </b-dropdown>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import axios from "axios"
import { setSchemaName, setTokenPair } from "@/utils/authenticationManager"

export default {
  name: "TeamSelection",
  data () {
    return {
      staticTextDefault: {
        changeTeamTitle: "Change Team"
      }
    }
  },
  props: {
    userTeams: {
      type: Array,
      required: false
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  methods: {
    ...mapActions({
      getUser: "auth/getUser",
      getTeamTreeForUser: "teams/getTeamTreeForUser"
    }),
    switchTeam (newTenant) {
      axios.post("/api/users/change_tenant", { schema_name: newTenant.schema_name })
        .then(({ data }) => {
          const { access, refresh } = data
          setTokenPair(access, refresh)
          setSchemaName(data.schema_name)
        })
        .then(() => this.getUser().then(() => {
          // update team tree
          this.getTeamTreeForUser()
        }))
        .catch(error => console.log(error))
    }
  }
}
</script>

<style scoped>

</style>
