<template>
  <div>
    <div>
      <b-table :items="selectUsersToShow"
               :fields="getFields()"
               :busy="loading"
               hover
               responsive
      >

        <div id="table-busy-slot-202012301554"
             slot="table-busy"
             class="text-center text-primary my-2"
        >
          <progress-indicator :loading-label="staticText.loadingLabel"></progress-indicator>
        </div>

        <template v-slot:cell(full_name)="data">
          <div class="my-3 d-flex align-middle">
            <span>{{data.item.full_name}}</span>
          </div>
        </template>

        <template v-slot:cell(registered_at)="data">
          <bao-date-time-display
            :value="data.item.registered_at"
            :datetime-display-class="'align-middle'"
          ></bao-date-time-display>
        </template>

        <template v-slot:cell(last_login)="data">
          <bao-date-time-display
            :value="data.item.last_login"
            :datetime-display-class="'align-middle'"
          ></bao-date-time-display>
        </template>

        <template v-slot:cell(active)="data">

          <div v-if="isNotCurrentAdmin(data)"
               class="my-3 d-flex align-middle"
          >
            <bao-toggle-button type="checkbox"
                               :is-active="data.item.is_active"
                               :label="staticText.activeLabel"
                               @toggle="updateUser(data.item, 'is_active')"
            ></bao-toggle-button>
          </div>
        </template>

        <template v-slot:cell(admin)="data">
          <!-- In case its the current user we do NOT display the admin switch so the current user cannot disable her admin rights by accident -->
          <div v-if="isNotCurrentAdmin(data)"
               class="my-3 d-flex align-middle"
          >
            <b-form-checkbox switch
                             v-model="data.item.is_admin"
                             @change="updateUser(data.item, 'is_admin')"
                             class="mb-auto mt-1"
            ></b-form-checkbox>
          </div>
        </template>

      </b-table>
    </div>

    <!--modals-->
    <b-modal id="user-update-warning-modal"
             v-model="showUserUpdateWarning"
             title="Warning"
             @ok="patchUser"
             @cancel="handleCancel"
             :hide-header-close="true"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true"
    >
      <div class="d-flex align-items-center">
        <img id="alert-icon-202103210125"
             src="/img/icons/round-exclamation-btn.svg"
             height="50px"
             class="mr-3"
        />
        <p>{{evalString(warningText)}}</p>
      </div>

    </b-modal>

  </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import BaoDateTimeDisplay from "@/apps/base/BaoDateTimeDisplay"
import axios from "axios"
import BaoToggleButton from "@/apps/base/BaoToggleButton"

export default {
  name: "TeamDisplay",
  components: {
    ProgressIndicator,
    BaoDateTimeDisplay,
    BaoToggleButton
  },
  data () {
    return {
      axios,
      users: [],
      activeUsers: [],
      staticTextDefault: {
        userLabel: "User",
        emailLabel: "E-Mail",
        registeredAtLabel: "Registered At",
        lastLoginLabel: "Last Login",
        activeLabel: "Active",
        statusLabel: "Status",
        adminLabel: "Team-Admin",
        actionsLabel: "Actions",
        removeFromTeamLabel: "Remove from Team",
        loadingLabel: "Loading...",
        activateConfirmText: "By activating the account {{selectedUser.email}} the user will be able to log in again." +
            "Do you want to continue?",
        deactivateConfirmText: "By deactivating the account {{selectedUser.email}} the user will no longer be able to" +
            " log into bao. Data will NOT be deleted. The account can be re-activated anytime. Do you want to continue?",
        addAdminConfirmText: "Confirm to make the selected account ({{selectedUser.email}}) a Team-Admin. You can " +
            "revoke the accounts right at any time.",
        removeAdminConfirmText: "Confirm to revoke Team-Admin rights for the selected account " +
            "({{selectedUser.email}}). You can change this at any time."
      },
      showUserUpdateWarning: false,
      warningText: "",
      selectedUser: null,
      fieldToChange: null,
      loading: false
    }
  },
  props: {
    uid: {
      required: true
    },
    team: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this._uid = this.uid
    if (this.team.open) this.getGroup()
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/user"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    currentUserIsAdmin () {
      return this.currentUser.is_admin
    },
    selectUsersToShow () {
      return this.team.showInactive ? this.users : this.activeUsers
    }
  },
  methods: {
    ...mapActions({
      getMembersForTeams: "teams/getMembersForTeams"
    }),
    getFields () {
      const fields = [
        {
          key: "full_name",
          label: this.staticText.userLabel,
          sortable: true,
          sortDirection: "desc",
          tdClass: "align-middle"
        },
        {
          key: "email",
          label: this.staticText.emailLabel,
          sortable: true,
          sortDirection: "desc",
          tdClass: "align-middle"
        },
        {
          key: "registered_at",
          label: this.staticText.registeredAtLabel,
          sortable: true,
          sortDirection: "desc",
          tdClass: "align-middle"
        },
        {
          key: "last_login",
          label: this.staticText.lastLoginLabel,
          sortable: true,
          sortDirection: "desc",
          tdClass: "align-middle"
        }
      ]
      if (this.currentUserIsAdmin) {
        fields.push(
          {
            key: "active",
            label: this.staticText.statusLabel,
            tdClass: "align-middle"
          },
          {
            key: "admin",
            label: this.staticText.adminLabel,
            tdClass: "align-middle"
          }
        )
      }

      return fields
    },
    getGroup () {
      this.loading = true
      this.getMembersForTeams([this.team.id]).then(result => {
        this.categorizeUsers(result[0].users)
        this.loading = false
      })
    },
    categorizeUsers (allUsers) {
      this.users = allUsers.map(user => this.setVariant(user))
      this.activeUsers = this.users.filter(user => user.is_active)
    },
    setVariant (user) {
      // let variant = ""
      // if (user.is_admin) variant = "primary"
      // if (!user.is_active) variant = "secondary"
      // user._rowVariant = variant
      return user
    },
    updateUser (user, field) {
      this.selectedUser = user
      // popup is shown after the toggle has been changed
      if (field === "is_active") {
        user[field] = !user[field]
        this.warningText = user[field] ? this.staticText.activateConfirmText : this.staticText.deactivateConfirmText
      } else {
        this.warningText = user[field] ? this.staticText.addAdminConfirmText : this.staticText.removeAdminConfirmText
      }
      this.showUserUpdateWarning = true
      this.fieldToChange = field
    },
    patchUser () {
      const data = {
        pk: this.selectedUser.pk, key: this.fieldToChange, value: this.selectedUser[this.fieldToChange]
      }
      this.axios.put(`/api/teams/${this.team.id}/patch_user`, data).then(() => {
        this.selectedUser = this.setVariant(this.selectedUser)
      })
    },
    handleCancel () {
      this.selectedUser[this.fieldToChange] = !this.selectedUser[this.fieldToChange]
      this.showUserUpdateWarning = false
    },
    isNotCurrentAdmin (data) {
      /**
        In case it's the current user we do NOT display the action buttons so the current user cannot disable or modify
       own account.
       * */
      return data.item.email !== this.currentUser.email
    }
  }
}
</script>

<style scoped>

</style>
