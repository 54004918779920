<template>
  <div class="team">
    <div v-for="team in teamTree"
         :key="team.id"
    >
      <div>
        <!-- root section-->
        <div :id="`${team.name}-${team.id}`"
             class="border-bottom"
        >
          <b-button block
                    class="bg-transparent team--header"
                    :area-controls="`collapse-${team.name}-${team.id}`"
                    @click.stop="toggleCollapse(team)"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-end">
                  <bao-toggle-button type="dropdown"
                                     :is-active="!!team.open"
                                     class="mr-2"
                                     @toggle="toggleCollapse(team)"
                  ></bao-toggle-button>
                  {{ team.name }}
                </div>

                <div v-if="currentUserIsAdmin"
                     class="ml-3 border-left"
                >
                  <div class="d-inline-flex">
                    <!--edit team name-->
                    <div v-if="!!team.parent"
                         :id="`edit-${team.name}-${team.id}`"
                         class="d-flex align-items-center ml-3"
                         @click.stop="toggleEditNameModal(team)"
                         v-b-tooltip="staticText.renameTeamLabel"
                    >
                      <img src="@/assets/svgs/edit-pen.svg"/>
                    </div>
                    <!--add existing users-->
                    <div class="d-flex align-items-center ml-3"
                         @click.stop="toggleAddMembersModal(team)"
                         v-b-tooltip="staticText.addUsersLabel"
                    >
                      <img src="@/assets/svgs/user-plus-icon.svg"/>
                    </div>
                    <!--delete team-->
                    <bao-delete-button
                      v-if="canBeDeleted(team)"
                      :id="`delete-btn-${team.name}-${team.id}`"
                      :icon="'fa fa-trash text-muted'"
                      :tooltip="staticText.deleteTeamLabel"
                      :tooltip-placement="'top'"
                      variant="btn-outline"
                      @delete="teamDelete(team)"
                      extra-class="py-0"
                    >
                      <span>{{ staticText.deleteTeamWarning }}</span>
                    </bao-delete-button>

                  </div>
                </div>
              </div>
              <!--toggle active users-->
              <div v-if="!!team.open"
                   class="d-flex"
                   @click.stop
              >
                <b-form-checkbox :id="`inactive-users-toggle-${team.id}`"
                                 v-model="showInactive"
                                 switch
                                 @change="toggleActiveUserDisplay(team)"
                >
                  {{staticText.inactiveLabel}}
                </b-form-checkbox>
              </div>

            </div>
          </b-button>
          <b-collapse :id="`collapse-${team.name}-${team.id}`"
                      v-model="team.open"
          >
            <div>
              <!--  team table display-->
              <team-display :team="team"
                            ref="renderedTeam"
                            :uid="team.id"
              ></team-display>
            </div>
          </b-collapse>

        </div>

        <!--recursive nested tree-->
        <div v-if="team.children.length>0"
             :id="team.id"
        >
          <TeamTreeDisplay :team-tree="team.children"
                           :root-tree="rootTree"
                           class="ml-4"
          ></TeamTreeDisplay>
        </div>

        <!--  Modals  -->
        <!-- Edit team name  Modal  -->
        <b-modal :id="`edit-modal-${team.name}-${team.id}`"
                 v-model="team.openNameEditModal"
                 :ok-title="staticText.renameTeamBtnLabel"
                 :cancel-title="staticText.cancelTitle"
                 :ok-disabled="oldName===team.name || team.name === ''"
                 @ok="renameTeam(team)"
                 @hide="oldName=null"
        >
          <b-form-input v-model="team.name"></b-form-input>
          <template v-slot:modal-title>
            <span>{{ staticText.renameTeamLabel }}</span>
          </template>
        </b-modal>

        <!-- Add existing team members  Modal  -->
        <b-modal :id="`add-member-modal-${team.name}-${team.id}`"
                 v-model="team.openAddMembersModal"
                 :no-close-on-backdrop="true"
                 :no-close-on-esc="true"
                 :ok-title="staticText.addBtnLabel"
                 :cancel-title="staticText.cancelTitle"
                 @ok="addTeamMembers(team)"
                 @hide="resetModalForm"
        >
          <template v-slot:modal-header-close>
            <img src="/img/icons/close-circle.svg" height="16px" width="16px" />
          </template>
          <template v-slot:modal-title>
            <span class="py-3">
              <img
                class="pr-1"
                src="@/assets/svgs/user-plus-icon-darker.svg"
                alt="Add Users Icon"
              />
              {{ staticText.addUsersFromOtherTeams }}
            </span>

          </template>

          <template #default>
            <b-form-group>
              <treeselect id="team-members-from"
                          :options="rootTree"
                          :normalizer="normalizer"
                          :placeholder="staticText.selectExistingTeamsPlaceholder"
                          :multiple="true"
                          :flat="true"
                          v-model="selectedTeams"
                          search-nested
                          @input="updateTeamMembers"
              ></treeselect>

              <div v-if="teamsWithMembers.length>0">
                <treeselect id="team-members"
                            v-model="selectedMembersToAdd"
                            :options="teamsWithMembers"
                            :normalizer="teamWithMembersNormalizer"
                            :loading="true"
                            :placeholder="staticText.selectUserPlaceholder"
                            :noChildrenText="staticText.noUsersAvailableText"
                            :disable-branch-nodes="true"
                            value-format="Object"
                            :multiple="true"
                            search-nested
                            class="mt-3"
                ></treeselect>

                <div class="mt-3" v-if="selectedMembersToAdd.length > 0">
                  <bao-toggle-button
                    :label="staticText.copyUsersSettingLabel"
                    :type="'checkbox'"
                    :is-active="copyUserSettings"
                    @toggle="toggleCopyUserSettings"
                  ></bao-toggle-button>
                </div>
              </div>
            </b-form-group>
          </template>
        </b-modal>
      </div>

    </div>
  </div>
</template>

<script>
import BaoToggleButton from "@/apps/base/BaoToggleButton"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import TeamDisplay from "@/apps/user/TeamManagementComponents/TeamDisplay"
import { mapActions, mapGetters } from "vuex"
import { treeSelectUtils } from "@/apps/user/TeamManagementComponents/TeamManagementUtils"
import Treeselect from "@riophae/vue-treeselect"

export default {
  name: "TeamTreeDisplay",
  components: {
    TeamDisplay,
    BaoToggleButton,
    BaoDeleteButton,
    Treeselect
  },

  data () {
    return {
      staticTextDefault: {
        inactiveLabel: "Show Inactive Users",
        noUsersAvailableText: "No users are available on this team.",
        renameTeamLabel: "Rename Team",
        renameTeamBtnLabel: "Rename",
        addUsersLabel: "Add Existing Users",
        addUsersFromOtherTeams: "Add Users From Other Teams",
        copyUsersSettingLabel: "Copy user's settings to new team",
        selectExistingTeamsPlaceholder: "Pick existing teams with users",
        selectUserPlaceholder: "Pick users from selected teams",
        addBtnLabel: "Add to this Team",
        deleteTeamLabel: "Delete Team",
        deleteTeamWarning: "Would you really like to delete this team and its data permanently?",
        cancelTitle: "Cancel"
      },
      teamsWithMembers: [],
      selectedMembersToAdd: [],
      selectedTeams: [],
      oldName: null,
      showInactive: false,
      copyUserSettings: false
    }
  },
  props: {
    teamTree: {
      type: Array,
      required: true
    },
    rootTree: {
      type: Array,
      required: true
    }
  },
  watch: {
    getTeamsToReload (val, oldVal) {
      if (val && val !== oldVal) {
        this.reloadTeams(val)
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/user",
      getTeamsToReload: "teams/getTeamsToReload"
    }),
    currentUserIsAdmin () {
      return this.currentUser.is_admin
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    ...treeSelectUtils.methods,
    ...mapActions({
      updateTeam: "teams/updateTeam",
      getMembersForTeams: "teams/getMembersForTeams",
      deleteTeam: "teams/deleteTeam"
    }),
    resetModalForm () {
      this.selectedMembersToAdd = []
      this.selectedTeams = []
    },
    reloadTeams (teamIds) {
      teamIds.forEach(teamId => {
        const teamComponent = this.getTeamComponent(teamId)
        if (teamComponent) { teamComponent.getGroup([teamId]) }
      })
    },
    async updateTeamMembers () {
      await this.getMembersForTeams(this.selectedTeams).then(result => {
        this.teamsWithMembers = result
      })
    },
    renameTeam (team) {
      this.updateTeam({ id: team.id, name: team.name })
    },
    addTeamMembers (team) {
      this.loading = true
      this.updateTeam({ id: team.id, name: team.name, users: this.selectedMembersToAdd, copy_user_settings: this.copyUserSettings }).then((result) => {
        if (team.open) {
          const expandedTeam = this.getTeamComponent(team.id)
          expandedTeam.categorizeUsers(result.users) // refreshes the team
        }
        this.loading = false
      })
    },
    getTeamComponent (teamId) {
      return this.$refs.renderedTeam.find(component => component._uid === teamId)
    },
    canBeDeleted (team) {
      // don't allow root team (which also represents client) to be deleted.
      return this.rootTree[0].id !== team.id
    },
    toggleEditNameModal (team) {
      this.oldName = team.name
      this.$set(team, "openNameEditModal", true)
    },
    toggleAddMembersModal (team) {
      this.$set(team, "openAddMembersModal", true)
    },
    toggleCollapse (team) {
      this.$set(team, "open", !team.open)
      // load team members only when collapsible section is expanded
      // load update only expanded team because not expanded teams will get updated on expand anyway.
      if (team.open) { this.loadTeamMembers(team) }
    },
    toggleCopyUserSettings () {
      this.copyUserSettings = !this.copyUserSettings
    },
    loadTeamMembers (team) {
      const teamComponent = this.getTeamComponent(team.id)
      teamComponent.getGroup([team.id])
    },
    toggleActiveUserDisplay (team) {
      team.showInactive = this.showInactive
      this.loadTeamMembers(team)
    },
    teamDelete (team) {
      this.deleteTeam(team)
    }
  }
}
</script>

<style scoped lang="scss">

.team {
  border-radius: 20px;
  background-color: $white80;
  &--header {
    background: inherit;
  }
}

</style>
